<template>
  <div class="call-to-action__container">
    <div class="call-to-action__inner-container">
      <div class="call-to-action__text">
        <div class="call-to-action__title">{{ textTitle }}</div>
        <div class="call-to-action__subtitle">
          {{ eventStore.eventSubtitle }}
        </div>
      </div>
      <div class="call-to-action__image">
        <NuxtImg
          v-if="logoSrc"
          :src="logoSrc"
          :width="changeXL(360, 224)"
          :height="changeXL(164, 100)"
          loading="lazy"
        />
      </div>
      <div v-if="isAuth" class="call-to-action__buttons">
        <SharedKitButton
          class="call-to-action__button"
          :color-preset="ColorPresets.DOTA.PRIMARY"
          :type-preset="TypePresets.NONE"
          :size-preset="changeXL(SizePresets.L, SizePresets.S)"
          :text="$t('topupBalance')"
          @click="handlePaymentOpen"
        />
        <SharedKitButton
          v-if="!hideSecretShop"
          class="call-to-action__button"
          :color-preset="ColorPresets.DOTA.PRIMARY"
          :type-preset="TypePresets.NONE"
          :size-preset="changeXL(SizePresets.L, SizePresets.S)"
          :text="$t('secretShop')"
          @click="handleSecretShopOpen"
        />
      </div>
      <div v-else class="call-to-action__auth">
        <GeneralAuthButtonController :amplitude-source-of-click="EAuthButtonPlaceClicked.FOOTER" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useEventStore } from '~/store/event/event.store';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';
import { EPaymentOpenedButtonPlaces } from '~/repository/amplitude/types/payment.types';

const { hideSecretShop } = useProjectSettings();

const route = useRoute();

const amplitudeFrom = computed(() => {
  const baseRouteName = useRouteBaseName();
  const baseName = baseRouteName(route);

  if (baseName === ROUTING.MAINPAGE.MAINPAGE.name) {
    return {
      payment: EPaymentOpenedButtonPlaces.FOOTER,
      secretShop: 'main_page_footer',
    };
  }

  if (baseName === ROUTING.MAINPAGE.NEW_CASES.name) {
    return {
      payment: EPaymentOpenedButtonPlaces.FOOTER,
      secretShop: 'new_cases_footer',
    };
  }

  if (baseName === ROUTING.MAINPAGE.TOP.name) {
    return {
      payment: EPaymentOpenedButtonPlaces.EVENT,
      secretShop: 'top_players_footer',
    };
  }

  return {
    payment: EPaymentOpenedButtonPlaces.FOOTER,
    secretShop: 'unknown_page_footer',
  };
});

const { t } = useI18n();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const authStore = useAuthStore();
const eventStore = useEventStore();

const { isAuth } = storeToRefs(authStore);

const logoSrc = computed(() => {
  return changeXL(eventStore.imageLogo.s, eventStore.imageLogo.case);
});

const textTitle = computed(() =>
  t(isAuth.value ? 'mainPage.callToAction.authTitle' : 'mainPage.callToAction.unAuthTitle'),
);

const handlePaymentOpen = () => {
  PaymentEvents.paymentOpened({ buttonPlace: amplitudeFrom.value.payment });
  return navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN));
};

const handleSecretShopOpen = () => {
  MainPageEvents.secretShopOpened({ 'Button Place': amplitudeFrom.value.secretShop });
  GlobalUtils.Routing.goExternalLink(useLinks().SECRETSHOP);
};
</script>
<style scoped lang="scss" src="./CallToActionController.scss"></style>
